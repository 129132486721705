


//Google sign up/sign in

//https://apis.google.com/js/platform.js?onload=initgapi

if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {
    var script = document.createElement('script');
    script.onload = function () {
        //load auth
        gapi.load('auth2', () => {
            //instantiate auth2
            gapi.auth2.init({
                client_id: '561512642221-tt8196cj13rimjlmqjsdjd07m32q2bs6.apps.googleusercontent.com',
                scope: 'profile email'
            }).then(res => {
                window.gauth2 = res;
            }).catch(err => {
                console.log(err);
            })
        });
    };
    script.src = "https://apis.google.com/js/platform.js";

    document.head.appendChild(script); //or something of the likes
}



//facebook

//this check is required, facebook hates local host
if (location.hostname !== "localhost" && location.hostname !== "127.0.0.1") {

    window.fbAsyncInit = function () {
        // FB JavaScript SDK configuration and setup
        FB.init({
            appId: '157030662638910', // FB App ID
            cookie: false,  // enable cookies to allow the server to access the session
            xfbml: false,  // parse social plugins on this page
            version: 'v9.0' // use graph api version 2.8
        });
    };


    // Load the JavaScript SDK asynchronously
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    // Facebook login with JavaScript SDK
    window.fbLogin = (success_cb, fail_cb) => {
        FB.login(function (response) {
            if (response.authResponse) {
                // handle sucess
                success_cb(response);
            } else {
                fail_cb(response)
            }
        }, { scope: 'email' });
    }


    // Logout from facebook
    window.fbLogout = (cb) => {
        FB.logout(cb());
    }
}
